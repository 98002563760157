
import Vue from 'vue';
import { EditorContent } from '@tiptap/vue-2';

import { TextEditorModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

import { EditorTools } from '@/features';

import { ActiveElement } from '../contracts';

export default Vue.extend({
  name: 'TextEditor',

  useInjectable: [Models.TextEditor],

  components: {
    EditorTools,
    EditorContent,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  created() {
    const content = this.content;
    this.editor = (this.textEditorEntity as TextEditorModelContract).createEditor(content);
  },

  computed: {
    activeElementInfo(): ActiveElement {
      const textType = {
        header_1: this.editor.isActive('heading', { level: 1 }) || this.editor.isActive('heading', { level: '1' }),
        header_2: this.editor.isActive('heading', { level: 2 }) || this.editor.isActive('heading', { level: '2' }),
        header_3: this.editor.isActive('heading', { level: 3 }) || this.editor.isActive('heading', { level: '3' }),
        text: this.editor.isActive('paragraph'),
      };
      const styles = {
        bold: this.editor.isActive('bold'),
        italic: this.editor.isActive('italic'),
        underline: this.editor.isActive('underline'),
      };
      const align = {
        left: this.editor.isActive({ textAlign: 'left' }),
        center: this.editor.isActive({ textAlign: 'center' }),
        right: this.editor.isActive({ textAlign: 'right' }),
      };
      const list = {
        bulleted: this.editor.isActive('bulletList'),
        numbered: this.editor.isActive('orderedList'),
      };
      const insertions = {
        link: this.editor.getAttributes('link').href || '',
        isImage: this.editor.isActive('image'),
        imageSize: this.editor.getAttributes('image').size,
      };

      const { from, to, empty } = this.editor.state.selection;
      return {
        selected: !empty ? this.editor.state.doc.textBetween(from, to, '') : '',
        styles,
        align,
        list,
        textType,
        insertions,
        color: this.editor.getAttributes('textStyle').color || '#000',
      };
    },
  },

  methods: {
    saveContent() {
      const content = this.editor.getJSON();
      this.$emit('save-content', content);
    },
    changeStyle(types) {
      const typesAction = {
        bold: 'toggleBold',
        italic: 'toggleItalic',
        underline: 'toggleUnderline',
      };
      types.forEach(type => this.editor.chain().focus()[typesAction[type]]().run());
    },
    changeAlign(type) {
      this.editor.chain().focus().setTextAlign(type).run();
    },
    changeColor(color) {
      this.editor.chain().focus().setColor(color).run();
    },
    changeTextType({ type, level }) {
      if (type === 'text') {
        this.editor.chain().focus().setParagraph().run();
      }
      this.editor.chain().focus().toggleHeading({ level }).run();
    },
    setList(type) {
      if (type === 'bulleted') {
        this.editor.chain().focus().toggleBulletList().run();
        return;
      }
      this.editor.chain().focus().toggleOrderedList().run();
    },
    setLink(url) {
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
        return;
      }
      if (!this.activeElementInfo.insertions.link && !this.activeElementInfo.selected) {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .setLink({ href: url, target: '_blank' })
          .command(({ tr }) => {
            tr.insertText(url);
            return true;
          })
          .run();
        return;
      }
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run();
    },
    setImage(url) {
      this.editor.chain().focus().setImage({ src: url.img, size: '4' }).run();
    },
    changeImageSize(size) {
      this.editor.chain().focus().updateAttributes('image', { size }).run();
    },
  },

  beforeDestroy() {
    this.editor?.destroy();
  },
});
