
import Vue from 'vue';
import { mapGetters, mapState } from 'vuex';
import { NewProposalRoute, Proposal } from '@/shared/types';
import { Routes } from '@/router/routes';
import {
  Status,
  TrackerContract,
  C360ModelContract,
  EnvServiceContract,
  LocalStorageServiceContract,
} from '@/injectables';
import { Models, Services } from '@/injectables/tokens';

import NotificationsDropdown from '@/widgets/common/notifications-dropdown.vue';
import UserDropdown from '@/widgets/common/user-dropdown.vue';
import { WindowResizeProvider } from '@/shared/ui';

import { C360Header, Product } from '@c360/ui';

export default Vue.extend({
  name: 'BaseAppBar',

  inject: ['$confirm'],

  useInjectable: [Models.Status, Services.Tracker, Services.Env, Models.C360, Services.LocalStorage],

  components: { NotificationsDropdown, UserDropdown, WindowResizeProvider, C360Header },

  props: {
    userDropdownHidden: {
      type: Boolean,
      default: false,
    },
    notificationsDropdownHidden: {
      type: Boolean,
      default: false,
    },
    hideVerticalLines: {
      type: Boolean,
      default: false,
    },
    hideLogo: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters('auth', {
      routes: 'authRoutes',
      isAdmin: 'isAdmin',
      isAgencyAdmin: 'isAgencyAdmin',
      isHelpCenterAvailable: 'isHelpCenterAvailable',
    }),
    ...mapState('agency', ['currentAgencyInfo']),

    ...mapState('notifications', ['notifications']),

    ...mapState('auth', ['user']),
    adminReviewEnabled(): boolean {
      return this.currentAgencyInfo.adminReviewEnabled;
    },
    product() {
      return Product.MediaPlanner;
    },
  },

  methods: {
    navigateToPortal(link) {
      window.location.href = (this.c360Entity as C360ModelContract).addRefreshTokenToReturnLink(link);
    },
    navigateToProduct(product) {
      const specialProductNames = ['help center'];
      const ssWebAppLinks = ['compulse oms'];

      if (ssWebAppLinks.includes(product.name)) {
        const refreshToken = (this[Services.LocalStorage] as LocalStorageServiceContract).getItem('mp_c360_refresh');
        product.link(refreshToken);
        return;
      }

      if (specialProductNames.includes(product.name)) {
        window.location.href = product.link;
        return;
      }

      window.location.href = (this.c360Entity as C360ModelContract).addRefreshToken(product.link);
    },
    menuSize(width: number) {
      return width <= 1400 ? 'small' : 'medium';
    },
    onLogoClick() {
      this.$router.push({ name: Routes.Home });
    },
    async onNavigationRequest(request: { route: NewProposalRoute; updateStatus?: Status }) {
      const { route, updateStatus } = request;

      if (updateStatus && this.currentAgencyInfo.adminReviewEnabled && this.isAgencyAdmin) {
        const { id, proposalId } = route.params;
        if (id && proposalId) {
          const { confirmed } = await this.$confirm.show({
            title: 'Begin Reviewing Proposal?',
            body: `This will change the proposal status to ${
              this.statusEntity.prettyStatus(updateStatus, this.adminReviewEnabled)?.short
            }`,
            confirmText: 'Yes',
            cancelText: 'Cancel',
          });

          if (!confirmed) return;

          await this.saveStatus({
            proposalId,
            newStatus: updateStatus,
          });
        }
      }
      this.$router.push({ name: route.name, params: route.params });
    },
    // TODO: rewrite or move
    async saveStatus({
      proposalId = this.$store.state.newProposal.newProposal.id,
      newStatus,
    }: {
      proposalId?: string;
      newStatus: Status;
    }): Promise<boolean> {
      let skipStatusUpdate = false;
      let successfullyUpdated: Proposal | null = null;

      const { id } = this.user;
      const proposal = this.$store.state.newProposal.newProposal;
      const oldStatus = proposal.status;

      if (this.currentAgencyInfo.adminReviewEnabled && newStatus === Status.ClientApproved) {
        successfullyUpdated = await this.$store.dispatch('newProposal/updateNewProposalStatus', {
          proposalPropertyId: proposalId,
          newStatus,
        });

        const submittedStatus = Status.SubmittedForReview;

        const { confirmed } = await this.$confirm.show({
          title: 'Submit for Admin Approval?',
          body: `This will change the proposal status to ${
            this.statusEntity.prettyStatus(submittedStatus, this.adminReviewEnabled)?.short
          }`,
          confirmText: 'Yes',
          cancelText: 'No',
        });

        if (confirmed) {
          newStatus = submittedStatus;
        } else {
          skipStatusUpdate = true;
        }
      }

      if (!skipStatusUpdate) {
        successfullyUpdated = await this.$store.dispatch('newProposal/updateNewProposalStatus', {
          proposalPropertyId: proposalId,
          newStatus,
        });
      }

      if (successfullyUpdated) {
        this.$store.dispatch('newProposal/setIsProposalMarketUpdated', false);
        this.$store.dispatch('proposal/triggerRefetchProposals');
      } else {
        return false;
      }

      const clientCategory = successfullyUpdated?.client?.category?.name?.split('^')?.join(', ');

      (this.tracker as TrackerContract).trackProposalStatusChange({
        proposal,
        userId: id,
        updatedAt: new Date().toISOString(),
        from: oldStatus,
        to: newStatus,
        clientCategory,
      });

      return !!successfullyUpdated;
    },
  },
});
