/* eslint-disable */

export enum Tabs {
  agencyLogo = 'Agency Logo',
  agencyDescription = 'Agency Description',
  agencyAddress = 'Agency Address',
  agencyContact = 'Agency Contact',
  agencyDefaultLayout = 'Default Layout',
}

export interface LayoutListItem {
  id: string;
  name: string;
  image: string;
}

export type Tab = Record<Tabs, string>;

export interface Address {
  address1?: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
  lat?: number;
  lon?: number;
}
