
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';

import Help from '@/pages/help-section';
import ManageAgency from '@/features/manage-agency';
import { GlobalMenu } from '@/features';
import { LogoutFeature } from '@/features';
import { SlideLibrary } from '@/widgets/common/slide-library';
import { BlankLibrary } from '@/widgets/common/blank-library';

import { Models, Services } from '../../injectables/tokens';
import { C360ModelContract, EnvServiceContract } from '../../injectables';

interface UserDropdownData {
  dialog: {
    manageAgency: boolean;
    slideLibrary: boolean;
    help: boolean;
    templatesLibrary: boolean;
  };
}

export default Vue.extend({
  name: 'UserDropdownWidget',

  useInjectable: [Services.Env, Models.C360],

  components: {
    GlobalMenu,
    ManageAgency,
    Help,
    SlideLibrary,
    BlankLibrary,
    LogoutFeature,
  },

  props: {
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data: (): UserDropdownData => ({
    dialog: {
      manageAgency: false,
      slideLibrary: false,
      templatesLibrary: false,
      help: false,
    },
  }),

  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters('auth', ['isHelpCenterAvailable']),

    ...mapState('agency', {
      currentAgency: 'currentAgencyInfo',
    }),

    ...mapState('client', {
      loadingAgencies: 'loadingAgencies',
      agencies: 'allAgencies',
    }),

    whiteLabeledEnv(): boolean {
      const url = window.location.host;

      return (this[Services.Env] as EnvServiceContract).isWhiteLabeledEnv(url);
    },

    withProfile() {
      return Boolean(this.user && this.user.name);
    },

    currentEnv() {
      return (this[Services.Env] as EnvServiceContract).getEnvironmentName(true);
    },

    userAgency() {
      return this.user.Agency;
    },

    agencyList() {
      return this.$store.state.auth.availableAgencies;
    },

    showAgencySelector() {
      return Boolean(
        (Array.isArray(this.agencyList) && this.agencyList.length > 1) ||
          (Array.isArray(this.agencyList) && this.agencyList?.length && this.agencyList[0]?.children?.length),
      );
    },
  },

  methods: {
    ...mapActions('auth', ['signoutUser']),
    navigateToHelpCenter() {
      const link = (this[Models.C360] as C360ModelContract).getC360Link(this.currentEnv);
      const helpRoute = 'help/category/15701009621271';

      window.location.href = `${link}/${helpRoute}`;
    },
  },
});
