
import Vue from 'vue';
import { mask } from 'vue-the-mask';
import { InputValidationRule } from 'vuetify';
import { debounce } from 'lodash';
import { UnsafeAny } from '@/shared/legacy/classes';
import { PitchAgency } from '@/shared/types';

import { Services, Models } from '@/injectables/tokens';
import {
  AgencyServiceContract,
  UploadServiceContract,
  ValidationServiceContract,
  GeocodeServiceContract,
  OutputServiceContract,
} from '@/injectables';

// components
import ImageUploader from '@/components/Basic/Uploader/ImageUploader.vue';
import LayoutSelectionItem from '@/features/output/layout-selection-item.vue';

import { DialogTabs, BaseDialog } from '@/shared/ui';

import { Address, Tabs, LayoutListItem } from '../contracts';

interface Data {
  tabs: Tabs[];
  tab: Tabs;
  addressLoading: boolean;
  fetchingLocations: boolean;
  isValidAddress: boolean;
  isValidDescription: boolean;
  isValidContacts: boolean;
  streetSearch: string;
  address: Address;
  locationAutocompleteList: UnsafeAny[];
  tempFile: File | null;
  agencyLogo: string;
  logoWasDeleted: boolean;
  activeAgency: PitchAgency;
  loading: boolean;
  dataUpdated: boolean;
  addressSetByUser: boolean;
  agencyDescription: string | null;
  mask: string;
  phone: string;
  email: string;
  defaultLayout?: string;
  layoutList: LayoutListItem[];
}

export default Vue.extend({
  name: 'ManageAgency',

  useInjectable: [
    Services.Upload,
    Services.Agency,
    Services.Validation,
    Services.Geocode,
    Services.Output,
    Models.Output,
  ],

  inject: ['$confirm', 'showSnackbar'],

  directives: { mask },

  components: {
    BaseDialog,
    ImageUploader,
    DialogTabs,
    LayoutSelectionItem,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    currentUserAgency: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    loadingAgencies: {
      type: Boolean,
      default: false,
    },
  },

  data(): Data {
    const currentUserAgency = this.currentUserAgency;

    return {
      tabs: Object.values(Tabs),
      tab: Tabs.agencyLogo,
      addressLoading: false,
      fetchingLocations: false,
      isValidAddress: true,
      isValidDescription: true,
      isValidContacts: true,
      streetSearch: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      },
      locationAutocompleteList: [],
      tempFile: null,
      agencyLogo: '',
      logoWasDeleted: false,
      activeAgency: {
        PropertyId: currentUserAgency,
        name: currentUserAgency,
      },
      loading: false,
      dataUpdated: false,
      addressSetByUser: false,
      agencyDescription: '',
      phone: '',
      email: '',
      mask: '1 (###) ###-####',
      defaultLayout: null,
      layoutList: [],
    };
  },

  async mounted() {
    await this.getLogo(this.currentAgency);
    await this.getDescription();
    await this.getContacts();
    await this.loadAddress();
    await this.getDefaultLayout();
    await this.getAvailableLayoutList();
  },

  watch: {
    streetSearch(newVal: string, oldVal: string): void {
      if (newVal === null && !this.addressSetByUser) {
        this.address.address1 = oldVal;
      } else if (newVal?.length >= 5) {
        this.fetchingLocations = true;

        this.fetchAutocompleteApi(newVal);
      }
    },
  },

  methods: {
    isSelected(item: LayoutListItem) {
      return this.defaultLayout === item.id;
    },
    setLayout(layout: LayoutListItem) {
      if (this.isSelected(layout)) {
        return;
      }
      this.defaultLayout = layout.id;
    },
    async getAvailableLayoutList() {
      this.loading = true;

      const { unwrap, isErr } = await (this[Services.Output] as OutputServiceContract).getLayouts({
        agencyId: this.currentAgency,
      });
      this.loading = false;

      if (isErr()) {
        this.layoutList = [];
        return;
      }
      const list = unwrap();

      this.layoutList = list;
    },
    async getDefaultLayout() {
      this.loading = true;

      const { unwrap, isErr } = await (this[Services.Agency] as AgencyServiceContract).getDefaultLayout(
        this.currentAgency,
      );
      this.loading = false;

      if (isErr()) {
        this.defaultLayout = null;
        return;
      }
      const result = unwrap();
      this.defaultLayout = result.defaultLayoutId;
    },
    async saveDefaultLayout() {
      this.loading = true;
      const { unwrap, isErr } = await (this[Services.Agency] as AgencyServiceContract).setDefaultLayout(
        this.defaultLayout,
      );
      this.loading = false;

      if (isErr()) {
        this.defaultLayout = null;
        return;
      }

      const result = unwrap();
      this.defaultLayout = result.defaultLayoutId;
    },
    setSelectedAddress(addressObj): void {
      if (addressObj) {
        this.address = addressObj;
        this.addressSetByUser = true;
        if (this.$refs.addressInput) {
          this.$refs.addressInput.reset();
          this.$refs.addressInput.blur();
        }
      }
    },

    async getLogo(agencyId) {
      this.loading = true;

      const { isErr, unwrap } = await (this.agencyService as AgencyServiceContract).getLogo(agencyId);

      this.loading = false;

      this.clearTempLogo();

      if (isErr()) {
        this.agencyLogo = '';
        return;
      }

      this.agencyLogo = unwrap();
    },

    async getDescription() {
      this.loading = true;

      const { isErr, unwrap } = await (this.agencyService as AgencyServiceContract).getDescription(this.currentAgency);

      this.loading = false;

      if (isErr()) {
        this.agencyDescription = '';
        return;
      }

      const response = unwrap();
      this.agencyDescription = response.description;
    },

    async saveDescription() {
      this.loading = true;

      await (this.agencyService as AgencyServiceContract).setDescription(this.currentAgency, this.agencyDescription);

      if (this.$refs?.agencyDescriptionForm?.resetValidation) {
        this.$refs.agencyDescriptionForm.resetValidation();
      }

      this.loading = false;
    },

    async getContacts() {
      this.loading = true;

      const { isErr, unwrap } = await (this.agencyService as AgencyServiceContract).getContacts(this.currentAgency);

      this.loading = false;

      if (isErr()) {
        this.email = '';
        this.phone = '';
        return;
      }

      const { email, phone } = unwrap();

      this.email = email;
      this.phone = phone;
    },

    async saveContacts() {
      this.loading = true;

      await (this.agencyService as AgencyServiceContract).setContacts(this.currentAgency, {
        email: this.email,
        phone: this.phone,
      });

      if (this.$refs?.agencyContactForm?.resetValidation) {
        this.$refs.agencyContactForm.resetValidation();
      }

      this.loading = false;
    },

    removeLogo() {
      this.dataUpdated = true;

      this.tempFile = null;

      this.logoWasDeleted = true;
    },

    /**
     * TODO: this doesn't work with description
     */
    async close() {
      if (this.dataUpdated) {
        try {
          const { canceled } = await this.$confirm.show({
            title: 'Unsaved changes',
            body: 'Are you sure you want to leave the modal window without saving changes?',
            cancelText: 'Leave',
            confirmText: 'Stay',
          });
          if (canceled) {
            this.clearTempLogo();
            this.$emit('input', false);
          }
        } catch (error) {
          this.clearTempLogo();
          this.$emit('input', false);
        }
        return;
      } else {
        this.dataUpdated = false;
        this.clearTempLogo();
        this.$emit('input', false);
      }
    },

    async getLogoLinkFromServer(file: File) {
      this.loading = true;

      const { isErr, unwrapErr, unwrap } = await (this.uploadService as UploadServiceContract).uploadLogo(file);

      this.loading = false;

      if (isErr()) {
        const { message } = unwrapErr();

        if (message) this.showSnackbar(message);
        return;
      }

      return unwrap().logo;
    },

    async updateLogo() {
      const agencyLogo = await this.getLogoLinkFromServer(this.tempFile);

      if (agencyLogo === undefined) return;

      this.loading = true;

      const { isErr, unwrapErr, unwrap } = await (this.agencyService as AgencyServiceContract).updateLogo(
        this.currentAgency,
        agencyLogo,
      );

      this.loading = false;

      if (isErr()) {
        const { message } = unwrapErr();

        if (message) this.showSnackbar(message);
        return;
      }

      this.agencyLogo = unwrap();

      this.clearTempLogo();
    },

    async deleteLogo() {
      this.loading = true;

      const { isErr, unwrapErr } = await (this.agencyService as AgencyServiceContract).removeLogo(this.currentAgency);

      this.loading = false;

      if (isErr()) {
        const { message } = unwrapErr();

        if (message) this.showSnackbar(message);
        return;
      }

      this.agencyLogo = '';

      this.clearTempLogo();
    },

    async loadAddress() {
      this.addressLoading = true;

      const { unwrap, isErr, unwrapErr } = await (this.agencyService as AgencyServiceContract).getAddress(
        this.currentAgency,
      );

      this.addressLoading = false;

      if (isErr()) {
        const { message } = unwrapErr();

        this.showSnackbar(message);
        return;
      }

      const address = unwrap();

      const emptyAddress = {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
      };

      this.address = { ...emptyAddress, ...address };

      if (this.$refs?.agencyAddressForm?.resetValidation) {
        this.$refs.agencyAddressForm.resetValidation();
      }
    },

    async saveAddress() {
      if (this.$refs.agencyAddressForm) {
        await this.$refs.agencyAddressForm.validate();
      }

      if (!this.isValidAddress || !this.$refs.agencyAddressForm) return;

      this.addressLoading = true;

      const { lat, lon } = await this.getLatLon(this.fullAddress);
      const { label, ...address } = this.address;
      const addressWithFreshLatLon = {
        ...address,
        ...(lat && { lat }),
        ...(lon && { lon }),
      };

      await (this.agencyService as AgencyServiceContract).setAddress(this.currentAgency, addressWithFreshLatLon);

      this.addressLoading = false;
    },

    setTempLogo(tempFile: File) {
      this.dataUpdated = true;

      this.tempFile = tempFile;

      this.logoWasDeleted = false;
    },

    async saveAllData() {
      // order of request is important! request to delete works quick
      // and BE side don't remove block flag to changing agency data
      this.loading = true;

      await this.saveAddress();
      await this.saveDescription();
      await this.saveContacts();
      await this.saveDefaultLayout();

      this.dataUpdated = false;

      if (this.tempFile !== null) {
        await this.updateLogo();
      } else if (this.logoWasDeleted) {
        await this.deleteLogo();
      }

      this.loading = false;
    },

    clearTempLogo() {
      this.tempFile = null;

      this.logoWasDeleted = false;
    },

    async getLatLon(address: string): Promise<{ lat?: string; lon?: string }> {
      const response = await (this.geocodeService as GeocodeServiceContract).searchByText(address);

      const { Latitude = '', Longitude = '' } =
        response?.Response?.View?.[0]?.Result?.[0]?.Location?.DisplayPosition || {};

      return { lat: Latitude, lon: Longitude };
    },

    fetchAutocompleteApi: debounce(async function (query: string) {
      const { isErr, unwrapErr, unwrap } = await (this.geocodeService as GeocodeServiceContract).searchByLocation(
        encodeURIComponent(query),
      );

      if (isErr()) {
        this.showSnackbar(unwrapErr().message, 'error');
        return;
      }

      this.locationAutocompleteList = unwrap();

      this.fetchingLocations = false;
    }, 500),
  },

  computed: {
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    isSaveButtonDisabled(): boolean {
      return (
        this.loading ||
        (this.tab === Tabs.agencyAddress && !this.isValidAddress) ||
        (this.tab === Tabs.agencyDescription && !this.isValidDescription) ||
        (this.tab === Tabs.agencyContact && !this.isValidContacts)
      );
    },
    fullAddress(): string {
      const { address1, city, state, zip } = this.address;

      return `${address1 || ''} ${city || ''}${state ? ', ' + state : ''} ${zip || ''}`;
    },

    items(): UnsafeAny[] {
      const matches = [];

      this.locationAutocompleteList.forEach(entry => {
        if (entry.position && entry.address && entry.address.label) {
          const value = {
            label: entry.address.label,
            address1: `${entry.address?.houseNumber ?? ''} ${entry.address?.street ?? ''}`.trim(),
            lat: entry.position.lat,
            lon: entry.position.lng,
            city: entry.address.city,
            zip: entry.address.postalCode,
            state: entry.address.stateCode,
          };

          matches.push({ text: entry.address.label, value });
        }
      });

      return matches;
    },
    rules(): Record<string, InputValidationRule> {
      const validationService: ValidationServiceContract = this.validationService;

      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
        maxLengthZipCode: validationService.maxLengthValidatorFactory(10),
        isAlphabetic: validationService.isAlphabetic(),
        maxLengthDescription: validationService.maxLengthValidatorFactory(500),
        email: validationService.notRequiredEmailValidatorFactory(),
        minPhoneLength: validationService.minLengthValidatorFactory(
          16,
          () => 'Phone number must have 10 numeric after country code',
        ),
      };
    },
    currentAgency(): string {
      return this.activeAgency.PropertyId;
    },
    tempUrl() {
      if (!this.tempFile) return '';

      const url = URL.createObjectURL(this.tempFile);

      return url;
    },
    currentImageLink() {
      if (this.tempFile) {
        return this.tempUrl;
      }

      if (this.logoWasDeleted) {
        return '';
      }

      return this.agencyLogo;
    },
  },
});
