
import { OutputEditorModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import Vue, { PropType } from 'vue';

interface LayoutListItem {
  name: string;
  image: string;
  id: string;
}

export default Vue.extend({
  name: 'layoutSelectionItem',

  useInjectable: [Models.OutputEditor],

  props: {
    item: {
      type: Object as PropType<LayoutListItem>,
      required: true,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    prettyLayoutName(): string {
      return (this[Models.OutputEditor] as OutputEditorModelContract).getCleanOutputLayoutName(this.item.name);
    },
  },
});
