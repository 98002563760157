
import Vue from 'vue';
import { OutputLayout, OutputSlide } from '@/shared/legacy/classes';
import { AgencyListItem, SlideLibrarySource } from '@/shared/types';

import { Routes } from '@/router/routes';

import { Models, Services } from '@/injectables/tokens';
import { OutputServiceContract, SlideModelContract } from '@/injectables';

import { BaseDialog, DialogTabs, SearchField } from '@/shared/ui';
import { SlidePreviewModal, SlidesList } from '@/features';
import { omit } from 'lodash';

export default Vue.extend({
  name: 'SlidesLibrary',

  useInjectable: [Models.Slide, Services.Output],

  components: {
    BaseDialog,
    DialogTabs,
    SearchField,
    SlidesList,
    SlidePreviewModal,
  },

  props: {
    canAddSlide: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    manageLibrary: {
      type: Boolean,
      default: false,
    },
    isAgencyChangingAvailable: {
      type: Boolean,
      default: false,
    },
    isSkipRebuildSlide: {
      type: Boolean,
      default: false,
    },
    colorScheme: {
      type: Object,
      default: () => ({}),
    },
  },

  data(): {
    selectedSlides: OutputSlide[];
    activeAgency: string;
    activeSubAgency: string;
    searchQuery: string;
    viewedSlide: OutputSlide;
    isViewShowed: boolean;
    tabs: ['User Library', 'Agency Library', 'Products Library', 'Shared Library'];
    tab: 'User Library' | 'Agency Library' | 'Products Library' | 'Shared Library';
    updateLibrarySlideLoading: boolean;
    layouts: OutputLayout[];
  } {
    return {
      tabs: ['User Library', 'Agency Library', 'Products Library', 'Shared Library'],
      tab: 'User Library',
      activeAgency: null,
      activeSubAgency: null,
      searchQuery: '',
      selectedSlides: [],
      isViewShowed: false,
      viewedSlide: null,
      updateLibrarySlideLoading: false,
      layouts: [],
    };
  },

  mounted(): void {
    this.fetchSlides();
    this.fetchProductSlides();
    this.initAgencies();
    this.fetchAvailableLayouts();
  },

  computed: {
    dialogIsOpen: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
    selectedLayout() {
      return this.$store.state.output.activeOutput.layout;
    },
    loadingUpdatedSlide(): boolean {
      return this.$store.state.output.updateLibrarySlideLoading || this.updateLibrarySlideLoading;
    },
    isAdmin(): boolean {
      return this.$store.getters['auth/isAdmin'];
    },
    isAgencyAdmin(): boolean {
      return this.$store.getters['auth/isAgencyAdmin'];
    },
    loadingSlides(): boolean {
      return this.$store.state.output.getLibrarySlidesLoading || this.$store.state.output.productSlidesLibraryLoading;
    },
    selectedAgency: {
      get(): { PropertyId: string; name: string } {
        const agencyId = this.activeAgency || this.$store.state.output.librarySlides.agencyId || this.userAgency;
        return {
          PropertyId: agencyId,
          name: agencyId,
        };
      },
      set(agency: { PropertyId: string; name: string }): void {
        this.activeAgency = agency.PropertyId;
      },
    },
    selectedSubAgency: {
      get(): { PropertyId: string; name: string } {
        const agencyId = this.activeSubAgency || this.$store.state.output.librarySlides.agencyId || this.userAgency;
        return {
          PropertyId: agencyId,
          name: agencyId,
        };
      },
      set(agency: { PropertyId: string; name: string }): void {
        this.activeSubAgency = agency.PropertyId;
      },
    },
    slideAlternatives(): OutputSlide[] {
      return this.$store.state.output.slideAlternatives;
    },
    allAgencies(): AgencyListItem[] {
      return this.$store.state.client.allAgencies?.list || [];
    },
    userSlides(): OutputSlide[] {
      return this.$store.state.output.librarySlides.user.filter(s => {
        if (s?.label) return s.label.toLowerCase().includes(this.searchQuery.toLowerCase());
        return s.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    agencySlides(): OutputSlide[] {
      let result: OutputSlide[] = [];
      const agencySlides: OutputSlide[] = this.$store.state.output.librarySlides.agency;

      result = [...this.slideAlternatives, ...agencySlides];
      return result.filter(s => {
        if (s?.label) return s.label.toLowerCase().includes(this.searchQuery.toLowerCase());
        return s.name && s.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    sharedSlides(): OutputSlide[] {
      return this.$store.state.output.librarySlides.shared.filter(s => {
        if (s?.label) return s.label.toLowerCase().includes(this.searchQuery.toLowerCase());
        return s.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    productLibrarySlides(): OutputSlide[] {
      return (this.$store.state.output.productLibrarySlides || []).filter(s => {
        if (s?.label)
          return (
            s.label.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            s.productSlideLabel.toLowerCase().includes(this.searchQuery.toLowerCase())
          );
        return s.name.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    actionsAvailable() {
      if (this.isViewProductSlideSelected) return false;
      if (this.viewedSlide.SlideSource === 'Agency') return this.isAgencyChangingAvailable;
      return true;
    },
    userAgency() {
      return this.$store.state.auth.user.Agency;
    },
    isViewProductSlideSelected() {
      return this.viewedSlide?.isProduct && this.tab === 'Products Library';
    },
    isViewedSlideReadonly() {
      return this.viewedSlide.readonly;
    },
    slideLibrarySource() {
      return SlideLibrarySource;
    },
  },

  methods: {
    async updateSlideName(payload: { id: string; name: string }): Promise<void> {
      const { id, name } = payload;
      const { isErr } = await (this[Services.Output] as OutputServiceContract).updateLibrarySlideName(id, name);

      if (isErr()) return;

      this.fetchSlides(true);
      this.fetchProductSlides();
    },
    initAgencies(): void {
      this.$store.dispatch('client/getAgencies');
    },
    fetchSlides(force = false): void {
      this.$store.dispatch('output/getLibrarySlides', {
        agencyId: this.selectedSubAgency.PropertyId || this.selectedAgency.PropertyId,
        layout: this.selectedLayout,
        force,
      });
    },
    async fetchAvailableLayouts(): Promise<void> {
      const { isErr, unwrap } = await (this[Services.Output] as OutputServiceContract).getLayouts({
        agencyId: this.selectedAgency.PropertyId,
      });
      if (isErr()) return;

      this.layouts = unwrap();
    },
    fetchProductSlides(): void {
      this.$store.dispatch('output/getProductLibrarySlides', {});
    },

    deleteSlide({ slide, slideSource }: { slide; slideSource: SlideLibrarySource }): void {
      (slideSource !== SlideLibrarySource.PRODUCT
        ? this.deleteLibrarySlide(slide, slideSource)
        : this.deletePackageOrProductSlide(slide)
      )
        .then(response => {
          if (!response) throw new Error();

          this.selectedSlides = [];
        })
        .catch(() => {
          this.showSnackbar('Unable to delete slide at this time');
        });
    },
    deleteLibrarySlide(slide, slideSource: SlideLibrarySource) {
      this.$store.dispatch('output/deleteLibrarySlide', {
        id: slide.slideLibraryId,
        slideSource,
      });
    },
    deletePackageOrProductSlide(slide) {
      return this.$store.dispatch('output/deleteLibraryProductSlide', {
        id: slide.id,
        productId: slide.productOrPackageConfigId,
      });
    },
    setSelectedSlide(slide: OutputSlide): void {
      if (this.manageLibrary || this.loadingUpdatedSlide) return;

      const slideIndex = this.selectedSlides.findIndex(el => el.id === slide.id);
      if (slideIndex === -1) {
        this.selectedSlides.push(slide);
        return;
      }
      this.selectedSlides.splice(slideIndex, 1);
    },

    async addSelectedSlide(): Promise<void> {
      if (this.isSkipRebuildSlide) {
        const preparedSlides = this.selectedSlides.map((slide, i) =>
          omit({ ...slide, name: 'Custom-' + (Date.now() - i * 2), defaultSlide: false }, 'id'),
        );
        this.$emit('add-slides', preparedSlides);
        this.dialogIsOpen = false;
        return;
      }

      this.updateLibrarySlideLoading = true;

      const preparedSlidesForRebuild = this.selectedSlides.map(slide =>
        omit(slide, ['productSlideLabel', 'productOrPackageConfigId']),
      );

      const updated = await this.$store.dispatch('output/rebuildLibrarySlide', preparedSlidesForRebuild);

      this.$emit('add-slides', updated);

      this.updateLibrarySlideLoading = false;
      this.dialogIsOpen = false;
    },

    viewSlide(slide: OutputSlide): void {
      this.viewedSlide = slide;
      this.isViewShowed = true;
    },

    slideIsSelected(slide: OutputSlide) {
      return (this.slideEntity as SlideModelContract).getIsSlideSelected(slide, this.selectedSlides);
    },

    slideSelectionFromView() {
      this.setSelectedSlide(this.viewedSlide);
      this.isViewShowed = false;
    },
    editSlide(slide = this.viewedSlide) {
      this.$router.push({
        name: Routes.ManageLibrarySlide,
        params: { agency: this.selectedAgency?.PropertyId, slideId: slide.slideLibraryId },
      });
    },
    addSlide() {
      this.$router.push({
        name: Routes.CreateLibrarySlide,
        params: { agency: this.selectedAgency.PropertyId },
      });
    },
  },
});
