
import Vue from 'vue';
import clonedeep from 'lodash.clonedeep';

import { Services, Models } from '@/injectables/tokens';
import { HelpSectionServiceContract, HelpPage, HelpSectionModelContract } from '@/injectables';

import { DropDownPageListElement } from '@/features';
import { BaseDialog, SearchField } from '@/shared/ui';
import { Editor as TextEditor, Viewer as TextRender } from '@/widgets/common/editor';

export default Vue.extend({
  name: 'HelpSectionDialog',

  inject: ['$confirm', 'showSnackbar'],

  useInjectable: [Services.HelpSection, Models.HelpSection],

  components: {
    BaseDialog,
    SearchField,
    DropDownPageListElement,
    TextEditor,
    TextRender,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data: (): {
    pages: HelpPage[];
    selected: { id: HelpPage['PropertyId']; parentId: HelpPage['PropertyId'] };
    searchQuery: string;
    editMode: boolean;
    loading: boolean;
    isSaving: boolean;
  } => ({
    selected: null,
    searchQuery: '',
    pages: null,
    editMode: false,
    loading: false,
    isSaving: false,
  }),

  async created() {
    this.pages = await this.getPages();
    const firstPage = this.pages[0];

    if (firstPage) this.selected = { id: firstPage.PropertyId, page: firstPage };
  },

  computed: {
    filteredPages(): HelpPage[] {
      if (this.searchQuery.length < 3) return this.pages;

      return this.pages.filter(
        el =>
          el.Title.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
          el?.PageList?.some(el => el.Title.toLowerCase().includes(this.searchQuery.toLowerCase())),
      );
    },
    content() {
      const page = this.getPageByIds(this.selected || {});
      const content = page?.PageContent || {};
      return clonedeep(content);
    },
  },

  methods: {
    close() {
      this.$emit('input', false);
    },

    getPageByIds({ id, parentId }) {
      if (!id) {
        return;
      }

      if (!parentId) {
        return this.pages.find(el => el.PropertyId === id);
      }

      const parentPage = this.pages.find(el => el.PropertyId === parentId);
      return parentPage.PageList.find(el => el.PropertyId === id);
    },

    getPageIndexes({ id, parentId }: { id: string; parentId?: string }): { pageIndex: number; parentIndex?: number } {
      if (!parentId) {
        return { pageIndex: this.pages.findIndex(el => el.PropertyId === id) };
      }

      const parentIndex = this.pages.findIndex(el => el.PropertyId === parentId);

      return {
        pageIndex: this.pages[parentIndex].PageList.findIndex(el => el.PropertyId === id),
        parentIndex,
      };
    },

    getPageByIndexes({ pageIndex, parentIndex }: { pageIndex: string; parentIndex?: string }) {
      if (typeof parentIndex !== 'number') {
        return this.pages[pageIndex];
      }

      return this.pages[parentIndex]?.PageList[pageIndex];
    },

    setPageByIndex({ pageIndex, parentIndex }, page) {
      if (typeof parentIndex !== 'number') {
        this.pages.splice(pageIndex, 1, page);
        return;
      }

      this.pages[parentIndex].PageList.splice(pageIndex, 1, page);
    },

    async showCancelEditingMode() {
      return (
        await this.$confirm.show({
          title: 'Changing will be not saved',
          body: 'All page changes will not saved. Would you like to stop editing without saving? ',
          confirmcontent: 'Yes',
          cancelcontent: 'Cancel',
        })
      ).confirmed;
    },

    async showMultiPagesDeleteModal() {
      return (
        await this.$confirm.show({
          title: 'All included pages will be deleted',
          body: `Deleting a root page make all included pages are deleted. Are you sure?`,
          confirmcontent: 'Yes',
          cancelcontent: 'Cancel',
        })
      ).confirmed;
    },

    async selectPage(selection) {
      if (this.editMode && !(await this.showCancelEditingMode())) {
        return;
      }

      this.selected = selection;
      this.editMode = false;
    },

    editPage(selection) {
      this.selected = selection;
      this.editMode = true;
    },

    async renamePage({ name, id, parentId }) {
      const { pageIndex, parentIndex } = this.getPageIndexes({ id, parentId });
      const page = this.getPageByIndexes({ pageIndex, parentIndex });

      const updatedPage = { ...page, Title: name };

      const result = await this.updatePage(updatedPage);

      if (result) {
        this.setPageByIndex({ pageIndex, parentIndex }, result);
      }
    },

    async deletePage({ id, parentId }) {
      const { PageList } = this.pages.find(el => el.PropertyId === id) || {};

      if (PageList?.length) {
        const confirmed = await this.showMultiPagesDeleteModal();

        if (!confirmed) {
          return;
        }
      }

      const { isErr } = await (this.helpSectionService as HelpSectionServiceContract).deletePage(id);

      if (isErr()) {
        return;
      }

      if (!parentId) {
        this.pages = this.pages.filter(page => page.PropertyId !== id);
        return;
      }

      const parentIndex = this.pages.findIndex(page => page.PropertyId === parentId);

      this.pages[parentIndex].PageList = this.pages[parentIndex].PageList.filter(page => page.PropertyId !== id);
    },

    async addPage({ rootId } = { rootId: null }) {
      const defaultPage = (this.helpSectionEntity as HelpSectionModelContract).getDefaultPage();

      let Index = this.pages.length || 0;

      if (rootId) {
        const parent = this.getPageByIndexes({ id: rootId });
        Index = parent?.PageList?.length || 0;
      }

      const { isErr, unwrap } = await (this.helpSectionService as HelpSectionServiceContract).createPage(
        {
          ...defaultPage,
          Index,
        },
        rootId,
      );

      if (isErr()) {
        return;
      }

      const page = unwrap();

      this.selected = { id: page.PropertyId, page: page };

      if (!rootId) {
        this.pages.push(page);
        return;
      }

      const { pageIndex } = this.getPageIndexes({ id: rootId });

      this.pages[pageIndex].PageList.push(page);
    },

    async savePage(content) {
      const { pageIndex, parentIndex } = this.getPageIndexes(this.selected);
      const page = this.getPageByIndexes({ pageIndex, parentIndex });

      const updatedPage = { ...page, PageContent: content };

      const result = await this.updatePage(updatedPage);

      if (!result) return;

      this.setPageByIndex({ pageIndex, parentIndex }, result);
      this.editMode = false;
    },

    async getPages() {
      this.loading = true;
      const { isErr, unwrap } = await (this.helpSectionService as HelpSectionServiceContract).getPages();
      this.loading = false;

      if (isErr()) {
        return [];
      }

      // TODO: need to handle sorting elements and sorting nested elements on BE?
      // TODO: need to find workaround without using clonedeep
      const pages = unwrap();
      const clonedData = clonedeep(pages);
      const sortedPages = clonedData.sort((a: HelpPage, b: HelpPage) => Number(a.Index) - Number(b.Index));
      return sortedPages;
    },

    async updatePage(newPage) {
      this.isSaving = true;
      const { isErr, unwrap } = await (this.helpSectionService as HelpSectionServiceContract).updatePage(newPage);

      this.isSaving = false;

      if (isErr()) {
        return;
      }

      return unwrap();
    },
  },
});
