
import Vue, { PropType } from 'vue';
import { MediaPlannerNotification, NotificationRouterAction } from '@/injectables';
import { Placeholder } from '@/shared/ui';

import { C360NotificationsDropdown, C360Icon } from '@c360/ui';

export default Vue.extend({
  name: 'NotificationsDropdown',

  components: { C360NotificationsDropdown, C360Icon, Placeholder },

  props: {
    allNotifications: {
      type: Array as PropType<MediaPlannerNotification[]>,
      default: () => [],
    },
  },

  data: (): {
    menuOpened: boolean;
    shouldClearNotifications: boolean;
    readNotifications: string[];
    removedNotifications: string[];
  } => ({
    readNotifications: [],
    removedNotifications: [],
    shouldClearNotifications: false,
    menuOpened: false,
  }),

  watch: {
    allNotifications(arr): void {
      if (arr.length) {
        this.checkMarkedNotifications();
      }
    },
    menuOpened(val: boolean): void {
      if (!val) {
        this.setReadNotifications();
      } else {
        this.shouldClearNotifications = true;
      }
    },
  },

  mounted(): void {
    this.checkMarkedNotifications();
  },

  methods: {
    // handle only NotificationRouterAction for now
    handleNotificationClick(action: NotificationRouterAction): void {
      if (action?.type === 'router') {
        const routeMap = {
          'proposal summary': 'proposalSummary',
          'proposal market': 'proposalMarket',
          'proposal solutions': 'proposalSolutions',
          'proposal finalize': 'proposalFinalize',
        };

        const paramMap = {
          clientId: 'id',
          id: 'proposalId',
        };

        const { page, props = null } = action;

        const routerObj = {
          name: routeMap?.[page] || 'home',
        };

        if (props) {
          const params = {};
          Object.entries(props).forEach(([key, value]) => {
            const param = paramMap[key];
            if (param) {
              params[param] = value;
            }
          });
          routerObj['params'] = params;
        }

        this.$emit('navigation-request', {
          route: routerObj,
          ...(action.triggerTargetStatus && { updateStatus: action.triggerTargetStatus }),
        });
      }

      this.menuOpened = false;
    },
    async checkMarkedNotifications(): Promise<void> {
      this.shouldClearNotifications = false;
      const readNotificationsString = localStorage.getItem('readNotifications');
      this.readNotifications = readNotificationsString ? JSON.parse(readNotificationsString) : [];
      const removedNotificationsString = localStorage.getItem('removedNotifications');
      this.removedNotifications = removedNotificationsString ? JSON.parse(removedNotificationsString) : [];
    },
    setReadNotifications(): void {
      if (!this.numOfUnreadNotifications) {
        return;
      }
      // should be filtered by expiry
      const newNotifications = this.C360notifications.map(n => `${n.id}_${n.title}`);
      const oldAndNewNotifications = [...this.readNotifications, ...newNotifications];
      const uniques = Array.from(new Set(oldAndNewNotifications));
      this.readNotifications = uniques;
      try {
        // safari private browsing disallows setItem()
        localStorage.setItem('readNotifications', JSON.stringify(uniques));
      } catch (err) {
        this.$log('error', 'NotificationsDialog: setReadNotifications', err?.message || err);
      }
      this.checkMarkedNotifications();
    },
    removeAllNotifications(): void {
      if (!this.notifications || this.notifications.length === 0) {
        return;
      }
      // should be filtered by expiry
      const newNotifications = this.C360notifications.map(n => `${n.id}_${n.title}`);
      const oldAndNewNotifications = [...this.removedNotifications, ...newNotifications];
      const uniques = Array.from(new Set(oldAndNewNotifications));
      this.removedNotifications = uniques;
      try {
        // safari private browsing disallows setItem()
        localStorage.setItem('removedNotifications', JSON.stringify(uniques));
      } catch (err) {
        this.$log('error', 'NotificationsDialog: removeAllNotifications', err?.message || err);
      }
      this.checkMarkedNotifications();
    },
    removeNotification(key: string): void {
      if (!this.notifications || !key) {
        return;
      }
      this.removedNotifications.push(key);
      try {
        // safari private browsing disallows setItem()
        localStorage.setItem('removedNotifications', JSON.stringify(this.removedNotifications));
      } catch (err) {
        this.$log('error', 'NotificationsDialog: removeAllNotifications', err?.message || err);
      }
      this.checkMarkedNotifications();
    },
  },

  computed: {
    notificationsNotEmpty(): boolean {
      return Array.isArray(this.notifications) && this.notifications.length > 0;
    },
    shouldShowNotifications(): boolean {
      return !this.shouldClearNotifications && this.numOfUnreadNotifications > 0;
    },
    notifications(): MediaPlannerNotification[] {
      return this.C360notifications.filter(n => !this.removedNotifications.includes(`${n.id}_${n.title}`))
        .map(n => ({ ...n, unread: !this.readNotifications.includes(`${n.id}_${n.title}`) }))
        .sort((a, b) => Number(a.unread) - Number(b.unread));
    },
    numOfUnreadNotifications(): number {
      return this.notifications.filter(n => n.unread).length;
    },
    C360notifications() {
      return this.allNotifications.map(({ message, ...others }) => ({ ...others, title: message }));
    },
  },
});
