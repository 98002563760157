
import Vue from 'vue';
import { OutputSlide } from '@/shared/legacy/classes';

import { Routes } from '@/router/routes';

import { Models, Services } from '@/injectables/tokens';
import { OutputEditorModelContract, OutputModelContract, OutputServiceContract } from '@/injectables';

import { BaseDialog, DialogTabs } from '@/shared/ui';
import { SlidePreviewModal, SlidesList } from '@/features';
import { DeclaredColor } from '@/store/output/types';

type GroupBlankSlide = { slides: OutputSlide[]; layoutId: string; colorScheme: Record<DeclaredColor, string> };

export default Vue.extend({
  name: 'SlidesLibrary',

  useInjectable: [Models.Slide, Models.Output, Models.OutputEditor, Services.Output],

  components: {
    BaseDialog,
    DialogTabs,
    SlidesList,
    SlidePreviewModal,
  },

  props: {
    canAddSlide: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Boolean,
      default: false,
    },
    isAgencyChangingAvailable: {
      type: Boolean,
      default: false,
    },
    isSkipRebuildSlide: {
      type: Boolean,
      default: false,
    },
  },

  data(): {
    selectedSlides: OutputSlide[];
    activeAgency: string;
    activeSubAgency: string;
    searchQuery: string;
    viewedSlide: OutputSlide;
    isViewShowed: boolean;
    viewedLayout: string;
    tabs: ['Slide Templates'];
    tab: 'Slide Templates';
    updateLibrarySlideLoading: boolean;
    slides: GroupBlankSlide[];
    layouts: any[];
  } {
    return {
      tabs: ['Slide Templates'],
      tab: 'Slide Templates',
      activeAgency: null,
      activeSubAgency: null,
      searchQuery: '',
      selectedSlides: [],
      isViewShowed: false,
      viewedSlide: null,
      viewedLayout: null,
      updateLibrarySlideLoading: false,
      slides: [],
      layouts: [],
    };
  },

  async mounted(): Promise<void> {
    await this.fetchAvailableLayouts();
    await this.fetchSlides();
  },

  computed: {
    dialogIsOpen: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit('input', newValue);
      },
    },
    loadingUpdatedSlide(): boolean {
      return this.$store.state.output.updateLibrarySlideLoading || this.updateLibrarySlideLoading;
    },
    loadingSlides(): boolean {
      return this.$store.state.output.getLibrarySlidesLoading || this.$store.state.output.productSlidesLibraryLoading;
    },
    selectedAgency: {
      get(): { PropertyId: string; name: string } {
        const agencyId = this.activeAgency || this.$store.state.output.librarySlides.agencyId || this.userAgency;
        return {
          PropertyId: agencyId,
          name: agencyId,
        };
      },
      set(agency: { PropertyId: string; name: string }): void {
        this.activeAgency = agency.PropertyId;
      },
    },
    slideAlternatives(): OutputSlide[] {
      return this.$store.state.output.slideAlternatives;
    },
    userSlides(): OutputSlide[] {
      return this.$store.state.output.librarySlides.user;
    },
    groupedSlides(): GroupBlankSlide[] {
      const startObject = Object.fromEntries(
        this.layouts.map(layout => [
          `${layout.id}`,
          { layoutId: layout.id, colorScheme: layout.colorScheme, slides: [] },
        ]),
      );

      return Object.values<GroupBlankSlide>(
        (this.slides as GroupBlankSlide[]).reduce(
          (acc, slide) => {
            const { layoutId } = slide;
            if (!acc[`${layoutId}`]) acc[`${layoutId}`] = { layoutId, colorScheme: null, slides: [] };
            acc[`${layoutId}`].slides.push(slide);
            return acc;
          },
          { ...startObject },
        ),
      ).sort((a: GroupBlankSlide, b: GroupBlankSlide) => a.layoutId.localeCompare(b.layoutId));
    },
    actionsAvailable() {
      return !this.viewedSlide.readonly;
    },
    userAgency() {
      return this.$store.state.auth.user.Agency;
    },
    currentColorScheme() {
      return this.groupedSlides?.find(el => el.layoutId === this.viewedLayout)?.colorScheme;
    },
  },

  methods: {
    async fetchSlides(): Promise<void> {
      const { isErr, unwrap } = await (this[Services.Output] as OutputServiceContract).getAgencyBlankSlides(
        this.selectedAgency.PropertyId,
      );

      if (isErr()) return;

      this.slides = (this[Models.Output] as OutputModelContract).flattenSlides(unwrap());
    },
    async fetchAvailableLayouts(): Promise<void> {
      const { isErr, unwrap } = await (this[Services.Output] as OutputServiceContract).getLayouts({
        agencyId: this.selectedAgency.PropertyId,
      });
      if (isErr()) return;

      this.layouts = unwrap();
    },
    deleteSlide(slide): void {
      this.$store
        .dispatch('output/deleteBlankSlide', {
          id: slide.slideLibraryId,
        })
        .then(response => {
          if (!response) throw new Error();

          this.slides = this.slides.filter(s => s.slideLibraryId !== slide.slideLibraryId);
        })
        .catch(() => {
          this.showSnackbar('Unable to delete slide at this time');
        });
    },

    viewSlide(slide: OutputSlide, layoutId): void {
      this.viewedSlide = slide;
      this.isViewShowed = true;
      this.viewedLayout = layoutId;
    },

    editSlide(slide = this.viewedSlide, layout = this.viewedLayout) {
      this.$router.push({
        name: Routes.ManageTemplateSlide,
        params: { agency: this.selectedAgency?.PropertyId, slideId: slide.slideLibraryId },
        query: { layout },
      });
    },
    addSlide(layout) {
      this.$router.push({
        name: Routes.CreateTemplateSlide,
        params: { agency: this.selectedAgency.PropertyId },
        query: { layout },
      });
    },
    getPrettyLayoutName(layout): string {
      const currentLayout = this.layouts.find(l => l.id === layout.layoutId);
      return (this[Models.OutputEditor] as OutputEditorModelContract).getCleanOutputLayoutName(currentLayout.name);
    },
  },
});
