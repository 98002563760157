
import Vue from 'vue';
import { EditorContent } from '@tiptap/vue-2';

import { Models } from '@/injectables/tokens';
import { TextEditorModelContract } from '@/injectables';

export default Vue.extend({
  name: 'TextRender',

  useInjectable: [Models.TextEditor],

  components: {
    EditorContent,
  },

  props: {
    content: {
      type: Object,
      required: true,
    },
  },

  watch: {
    content: {
      handler(val) {
        this.editor.commands.setContent(val);
      },
      deep: true,
    },
  },

  data() {
    return {
      editor: null,
    };
  },

  created() {
    this.editor = (this.textEditorEntity as TextEditorModelContract).createEditor(this.content, false);
  },

  beforeDestroy() {
    this.editor?.destroy();
  },
});
